import Cards from "./cards";
import moment from "moment";
import i18n from "src/locales/i18n";
import { object, string } from "yup";
import FilterCard from "./filterCard";
import Page from "src/components/Page";
import { Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { PATH_USER } from "src/routes/paths";
import DataList from "./components/dataTable";
import useLocales from "src/hooks/useLocales";
import serializeDate from "src/utils/serialize-date";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetchSummary from "../hooks/useFetchSummary";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import useFetchWitPagination from "../hooks/useFetchWithPagination";

const defaultValues = {
	start_date: "",
	end_date: "",
	user_id: "",
	payment_type: "all",
	// rows_page: "10",
};

const schema = object().shape({
    start_date: string()
      .test("is-valid", "errors.date.valid_date.test", (v) => {
        if (v === null) return true;
        return moment(v, "YYYY/MM/DD").isValid();
      })
      .test("is-valid", "errors.date.valid_start.test", (v, ctx) => {
        if (v === null) return true;
        if (ctx.parent.end_date === null) return true;
        return (moment(ctx.parent.end_date, "YYYY/MM/DD").diff(moment(v, "YYYY/MM/DD")) >= 0);
      })
      .transform((v) => serializeDate(v))
      .nullable(),
    end_date: string()
      .test("is-valid", "errors.date.valid_date.test", (v) => {
        if (v === null) return true;
        return moment(v, "YYYY/MM/DD").isValid();
      })
      .test("is-valid", "errors.date.valid_end.test", (v, ctx) => {
        if (v === null) return true;
        if (ctx.parent.start_date === null) return true;
        return (moment(ctx.parent.start_date, "YYYY/MM/DD").diff(moment(v, "YYYY/MM/DD")) <= 0);
      })
      .transform((v) => serializeDate(v))
      .nullable(),
});

const Index = () => {
  const { translate } = useLocales();

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const filter = methods.watch();

  const _data = [
    {
      title: i18n.t("userFinancial.depositWallet.balance"),
      icon: "arcticons:priceconverter",
      color: "#d279a6",
      key: "balance",
    },
    {
      title: i18n.t("userFinancial.depositWallet.transferOut"),
      icon: "bx:transfer-alt",
      color: "#6699ff",
      key: "transfer_out",
    },
    {
      title: i18n.t("userFinancial.depositWallet.creditedByAdmin"),
      icon: "material-symbols:admin-panel-settings",
      color: "#26a69a",
      key: "credited_by_admin",
    },
  ];

  const { data, fetchData, ...rest } = useFetchWitPagination("deposit-wallet", filter);

  const summary = useFetchSummary("deposit-wallet-data");

  return (
    <div>
      <Page
        title={translate("userFinancial.depositWallet.depositWalletTitile")}
      >
        <Box sx={{ p: 2 }}>
          <HeaderBreadcrumbs
            heading={translate("userFinancial.depositWallet.depositWallet")}
            links={[
              { name: "Dashboard", href: PATH_USER.root },
              { name: translate("userFinancial.depositWallet.depositWallet") },
            ]}
          />

          <Grid container spacing={3}>
            {_data.map((props) => (
              <Grid item xs={12} md={4}>
                <Cards {...props} total={parseFloat(summary?.summary[props.key])} />
              </Grid>
            ))}

            {/* <Grid item xs={12} md={12}>
              <PayNow balance={summary.balance} />
            </Grid> */}

            <Grid item xs={12} md={12}>
              <FilterCard
                methods={methods}
                fetchData={(filter) => {
                  fetchData(1, filter);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <DataList data={data} {...rest} />
          </Grid>
        </Box>
      </Page>
    </div>
  );
};

export default Index;
