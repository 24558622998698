import { Box, Grid } from "@mui/material";

import GetReport from "src/components/getReport";
import { FormProvider, RHFSelect } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import Users from "src/components/users";
import useLocales from "src/hooks/useLocales";
import { isBinary } from "src/utils/isBinary";

const DataFilter = ({ methods, onFilter, isEWallet = false, isDWallet = false }) => {
  const { translate } = useLocales();
  // const { methods, onSubmit } = useFilter(fetchData);

  return (
    <>
      <Grid item xs={12} md={12} sx={{ p: 2, mb: 2, mt: 1 }}>
        <FormProvider methods={methods} onSubmit={onFilter}>
          <Box
            sx={{
              display: "grid",
              columnGap: 2,
              rowGap: 3,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(5, 1fr)",
              },
            }}
          >
            <RHFDatePicker label={translate("adminFinancial.Ewallet.pickStartDate")} name="start_date" size="small" />
            <RHFDatePicker label={translate("adminFinancial.Ewallet.pickEndDate")} name="end_date" size="small" />
            <Users label={translate("adminFinancial.Ewallet.userName")} name="user_id" size="small" />

            {isDWallet && (
                <RHFSelect name="payment_type" label={translate("userFinancial.depositWallet.amountType")} size="small">
                    <option value="all">{translate("userFinancial.depositWallet.all")}</option>
                    <option value="released">{translate("userFinancial.depositWallet.released")}</option>
                    <option value="pending">{translate("userFinancial.depositWallet.pending")}</option>
                    <option value="failed">{translate("userFinancial.depositWallet.failed")}</option>
                    <option value="rejected">{translate("userFinancial.depositWallet.rejected")}</option>
                    <option value="finished">{translate("userFinancial.depositWallet.finished")}</option>
                    <option value="approved">{translate("userFinancial.depositWallet.approved")}</option>
                    <option value="fund_transfer">{translate("userFinancial.depositWallet.fundTransfer")}</option>
                    <option value="plan_purchase">{translate("userFinancial.depositWallet.planPurchase")}</option>
                    <option value="fund_transfer">{translate("userFinancial.depositWallet.fundTransfer")}</option>
                    <option value="self_transfer">{translate("userFinancial.depositWallet.selfTransfer")}</option>
                    <option value="referral_bonus">{translate("userFinancial.depositWallet.referralBonus")}</option>
                    <option value="achievement_bonus">{translate("userFinancial.depositWallet.achievementBonus")}</option>
                    <option value="first_order_bonus">{translate("userFinancial.depositWallet.firstOrderBonus")}</option>
                    <option value="binary_bonus">{translate("userFinancial.depositWallet.binaryBonus")}</option>
                    <option value="credited_by_admin">{translate("userFinancial.depositWallet.creditedByAdmin")}</option>
                    <option value="deducted_by_admin">{translate("userFinancial.depositWallet.deductedByAdmin")}</option>
                </RHFSelect>
            )}

            {isEWallet && (
                <RHFSelect name="payment_type" label={translate("userFinancial.eWallet.amountType")} size="small">
                    <option value="all">{translate("userFinancial.eWallet.all")}</option>
                    <option value="lifestyle_bonus_monthly">{translate("lifeStyleBonusMonthly")}</option>
                    <option value="global_leadership_poolbonus">{translate("globalLeadershipPoolbonus")}</option>
                    <option value="fast_start_bonus">{translate("fastStartBonus")}</option>
                    <option value="dualteam_bonus">{translate("dualTeamBonus")}</option>
                    <option value="matching_bonus">{translate("matchingBonus")}</option>
                    <option value="rank_bonus">{translate("rankBonus")}</option>

                    <option value="referral_bonus">{translate("userFinancial.eWallet.referral_bonus")}</option>
                    <option value="achievement_bonus">{translate("userFinancial.eWallet.achievement_bonus")}</option>
                    <option value="first_order_bonus">{translate("userFinancial.eWallet.first_order_bonus")}</option>

                    <option value="credited_by_admin">{translate("userFinancial.eWallet.credited_by_admin")}</option>
                    <option value="deducted_by_admin">{translate("userFinancial.eWallet.deducted_by_admin")}</option>
                    {isBinary && <option value="binary_bonus">{translate("userFinancial.eWallet.binary_bonus")}</option>}
                </RHFSelect>
            )}

            <GetReport size="medium" />
          </Box>
        </FormProvider>
      </Grid>
    </>
  );
};

export default DataFilter;
