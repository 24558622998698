import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider, RHFSelect } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import useLocales from "src/hooks/useLocales";
import { isBinary } from "src/utils/isBinary";
import Users from "src/pages/admin/communications/help-center/ticket/components/form/components/users";

const defaultValues = {
  start_date: "",
  end_date: "",
  user_id: "",
  payment_type: ""
};

const Filter = ({ setFilter, report }) => {
  const methods = useForm({ defaultValues });

  const { translate } = useLocales();

  const onSubmit = (data) => {
    setFilter(data);
  };

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: report === 'income' ? "repeat(5, 1fr)" : "repeat(4, 1fr)",
          },
          rowGap: 3,
          columnGap: 3,
          marginBottom: "2rem",
        }}
      >
        <RHFDatePicker
          label={translate("adminFinancial.payout.pickStartDate")}
          name="start_date"
          size="small"
        />

        <RHFDatePicker
          label={translate("adminFinancial.payout.pickEndDate")}
          name="end_date"
          size="small"
        />

        <Users size="small" />

        {report === 'income' && (
            <RHFSelect name="payment_type" label={translate("userFinancial.eWallet.amountType")} size="small">
                <option value="all">{translate("userFinancial.eWallet.all")}</option>
                <option value="life_style_bonus">{translate("lifeStyleBonus")}</option>
                <option value="global_leadership_poolbonus">{translate("globalLeadershipPoolbonus")}</option>
                <option value="fast_start_bonus">{translate("fastStartBonus")}</option>
                <option value="dual_team_bonus">{translate("dualTeamBonus")}</option>
                <option value="matching_bonus">{translate("matchingBonus")}</option>
                <option value="rank_bonus">{translate("rankBonus")}</option>

                <option value="referral_bonus">{translate("userFinancial.eWallet.referral_bonus")}</option>
                <option value="achievement_bonus">{translate("userFinancial.eWallet.achievement_bonus")}</option>
                <option value="first_order_bonus">{translate("userFinancial.eWallet.first_order_bonus")}</option>

                <option value="credited_by_admin">{translate("userFinancial.eWallet.credited_by_admin")}</option>
                <option value="deducted_by_admin">{translate("userFinancial.eWallet.deducted_by_admin")}</option>
                {isBinary && <option value="binary_bonus">{translate("userFinancial.eWallet.binary_bonus")}</option>}
            </RHFSelect>
        )}

        <Button type="submit" variant="contained" size="medium">
          Get Report
        </Button>
      </Box>
    </FormProvider>
  );
};

export default Filter;
